<template>
	<div>
		<b-form-select v-model="selected"
		               :options="options"
		               :class="validateError ? 'box-border-color':''"
		               @input="handleInput"></b-form-select>
		<span class="invalid-feedback-custom"
		      v-if="validateError"
		      v-html="validateError">
        </span>
	</div>
</template>

<script>
	export default {
		props: {
			value: {
				default: null
			},
			validateError: {
				type: String,
				default: ''
			}
		},
		watch: {
			value: function (newValue) {
				this.selected = newValue
			},
		},
		created() {
			this.selected = this.value
		},
		data() {
			return {
				selected: null,
				options: [
					{
						value: null,
						text: this.$t('select'),
					},
					{
						value: 'transkript',
						text: this.$t('transcript'),
					},
					{
						value: 'student-certificate',
						text: this.$t('student_certificate'),
					}
				]
			}
		},
		methods: {
			handleInput(event) {
				this.$emit('input', event)
			}
		}
	}
</script>
